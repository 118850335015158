<template>
    <div class="action-workday">
        <template>
            <div class="message">
                <div class="message-content">
                    <div>
                        <p>지금은 상담가능한 시간이 아닙니다.</p>
                        <p>입력하신 메세지는 전달되었으니 채팅이 가능한 시간에 빠르게 답변드리겠습니다.</p>
                    </div>
                    <div class="grey-box m-t-8">
                        <p class="c-grey-07 spoqa-f-bold f-12">상담가능시간</p>
                        <p class="f-16 spoqa-f-bold c-black">평일 오전 9시~오후 6시</p>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'ActionWorkingTime',
}
</script>

<style scoped lang="scss">
.grey-box {
    background: $blue-background !important;
}
</style>
